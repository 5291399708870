import './App.css';
import React, { useState } from 'react';

// Create a FileReader instance
const reader = new FileReader();

// Create a File object for the uploaded css file
let uploadedFile;

// Component to render the upload and download section
const FileUpload = () => {
  const [css, setCSS] = useState('');
  const [downloadFile, setDownloadFile] = useState(null);
  // Create a map from hex color codes to variables
  let count = 1;
  let colorMap = {};

  const addVariablesToCSS = (cssContent, variables) => {
    const variablesCSS = variables.map(([hsl, variable]) => `${variable}: ${hsl};`).join('\n');
    return `:root {\n${variablesCSS}\n}\n\n${cssContent}`;
  };

  // Handle file upload
  const handleFileUpload = (e) => {
    uploadedFile = e.target.files[0];
    reader.onload = (e) => {
      setCSS(e.target.result);
    };
    reader.readAsText(uploadedFile);
  };

  // Handle download
  const handleDownload = () => {
    // Find and replace hex color codes with HSL color codes
    const updatedCSS = css.replace(/#([0-9A-F]{6}|[0-9A-F]{3})/gi, (hex) => {
      if (hex.length === 4) {
        hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
      }

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const r = parseInt(result[1], 16) / 255;
      const g = parseInt(result[2], 16) / 255;
      const b = parseInt(result[3], 16) / 255;
      const hsl = rgbToHsl(r, g, b);

      const hexColor = `hsl(${hsl[0]},${hsl[1]}%,${hsl[2]}%)`;
      if (colorMap[hexColor]) {
        return colorMap[hexColor];
      } else {
        colorMap[hexColor] = `var(--color-${count})`;
        count += 1;
        return colorMap[hexColor];
      }
    });

    const uniqueVariables = Object.entries(colorMap);
    const updatedCSSWithVariables = addVariablesToCSS(updatedCSS, uniqueVariables);

    // Create a new Blob object with the updated css content
    const newBlob = new Blob([updatedCSSWithVariables], { type: 'text/css' });
    setDownloadFile(newBlob);
  };

  // Render the upload and download section
  return (
    <div className="d-flex justify-content-center">
      <input type="file" className="mt-5" onChange={handleFileUpload} />
      <button className="mt-5 mx-3" onClick={handleDownload}>Download Updated CSS</button>
      {downloadFile && (
        <a
          href={URL.createObjectURL(downloadFile)}
          download="updated.css"
          rel="noopener noreferrer"
        >
          Download
        </a>
      )}
    </div>
  );
};

// Helper function to convert RGB color codes to HSL color codes
const rgbToHsl = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
};

// Render the component
export default FileUpload;